/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Application from '../config/application';
import {PageUrl} from '../Constants';
import PropTypes from 'prop-types';
import {formatDate} from '../Util';

const Footer = React.memo(({phone}) => {
  return (
    <footer id="footer">
      <section className="section bg-light shadow-md pt-4 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="featured-box text-center">
                <div className="featured-box-icon">
                  {' '}
                  <i className="fas fa-lock" />{' '}
                </div>
                <h4>100% Secured Payments</h4>
                <p>
                  We use industry leading technology while processing your
                  payment.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="featured-box text-center">
                <div className="featured-box-icon">
                  {' '}
                  <i className="fas fa-thumbs-up" />{' '}
                </div>
                <h4>Trust pay</h4>
                <p>100% Payment Protection. Easy Refund Policy.</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="featured-box text-center">
                <div className="featured-box-icon">
                  {' '}
                  <i className="far fa-life-ring" />{' '}
                </div>
                <h4>24/7 Support</h4>
                <p>
                  Happy to help. Just call our 24/7 customer service whenever
                  needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0">
            <p>We support</p>
            <ul className="payments-types">
              <li>
                <img
                  data-toggle="tooltip"
                  src={require('../images/payment/visa.png').default}
                  alt="visa"
                  title="Visa"
                />
              </li>
              <li>
                <img
                  data-toggle="tooltip"
                  src={require('../images/payment/discover.png').default}
                  alt="discover"
                  title="Discover"
                />
              </li>
              <li>
                <img
                  data-toggle="tooltip"
                  src={require('../images/payment/american.png').default}
                  alt="american express"
                  title="American Express"
                />
              </li>
              <li>
                <img
                  data-toggle="tooltip"
                  src={require('../images/payment/mastercard.png').default}
                  alt="mastercard"
                  title="Mastercard"
                />
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-4 Add_Tollfree">
            <h6>Have Questions?</h6>
            <h5 class="text-color">
              <a href={`tel:${phone}`}>
                <i className="fa fa-phone" aria-hidden="true"></i>
                {phone}
              </a>
            </h5>
            <p>
              {Application.ORG_ADD_LINE1}
              <br />
              <span>{Application.ORG_ADD_LINE2}</span>
            </p>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            {/*<p>
              Please subscribe to our newsletter to get notified about our
              latest offers
            </p>
            <div className="input-group newsletter">
              <input
                className="form-control"
                placeholder="Your Email Address"
                name="newsletterEmail"
                id="newsletterEmail"
                type="text"
              />
              <span className="input-group-append">
                <button className="btn btn-secondary" type="submit">
                  Subscribe
                </button>
              </span>{' '}
  </div>*/}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-copyright">
          <ul className="nav justify-content-center">
            <li className="nav-item">
              {' '}
              <RouterLink className="nav-link active" to={PageUrl.ABOUT}>
                About Us
              </RouterLink>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <RouterLink className="nav-link" to={PageUrl.DESTINATION}>
                Destinations
              </RouterLink>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <RouterLink className="nav-link" to={PageUrl.CONTACT}>
                Contact Us
              </RouterLink>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <RouterLink className="nav-link" to={PageUrl.PRIVACY}>
                Privacy Policy
              </RouterLink>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <RouterLink className="nav-link" to={PageUrl.TERMS}>
                Terms & Condition
              </RouterLink>{' '}
            </li>
            <li className="nav-item">
              {' '}
              <RouterLink
                className="nav-link"
                to={PageUrl.CANCEL_REFUND_POLICY}>
                Cancel & Refund Policy
              </RouterLink>{' '}
            </li>
          </ul>
          {Application.DISCLAIMER ? (
            <>
              <p className="copyright-text">Disclaimer:</p>
              <p className="copyright-text"> {Application.DISCLAIMER}</p>
            </>
          ) : null}

          <p className="copyright-text">
            Copyright © {formatDate(new Date(), 'yyyy')}{' '}
            {Application.ORG_DISPLAY_NAME}. All Rights Reserved.
          </p>
        </div>
      </div>
      <div className="phone-sticky-footer">
      <div className="Markup phonefooter">
      <div className="footerphonetag">CALL US FOR UNPUBLISHED DEALS</div>
      <div className="container">
        <div classNmae="mt-6">
          <span id="footer_href" className="footer-text-call">{Application.SUPPORT_PHONE}</span></div>
        <div classNmae="mt-6">
        <a href={`tel:${Application.SUPPORT_PHONE}`} id="btn_footer_text_call"  className="button btn-small yellow">Call Now</a>
        </div>
      </div>
      </div>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  phone: PropTypes.string,
};

Footer.defaultProps = {
  phone: Application.SUPPORT_PHONE
};

export default Footer;
