import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
  <div id="main-wrapper">
    <Helmet title={composePageTitle('Privacy Policy')} />
    <Header />
    <section className="page-header page-header-dark bg-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>Privacy Policy</h1>
          </div>
          <div className="col-md-4">
            <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
              <li>
                <RouterLink to={PageUrl.HOME}>Home</RouterLink>
              </li>
              <li className="active">Privacy Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div id="content">
      <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          <div>
  
            <p>Welcome to {Application.ORG_DISPLAY_NAME}, where your privacy and data security are our utmost priorities. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our website. By using {Application.ORG_DISPLAY_NAME}, you consent to the practices described in this policy.</p>
            <h5>Information We Collect:</h5>
            <p>We collect various types of information to provide you with a seamless and personalized experience:</p>
            <ul>
              <li><strong>Personal Information:</strong> When you make a booking or create an account, we may collect your name, contact details, payment information, and other relevant information.</li>
              <li><strong>Usage Data:</strong> We gather data about how you interact with our website, including pages visited, search queries, and preferences.</li>
              <li><strong>Cookies and Tracking:</strong> Our website uses cookies and similar technologies to enhance your browsing experience and collect information about your usage patterns.</li>
            </ul>
            <h5>How We Use Your Information:</h5>
            <ul>
              <li><strong>Booking and Services:</strong> We use your information to facilitate bookings, manage reservations, and provide customer support.</li>
              <li><strong>Personalization: </strong>Your data helps us personalize your experience by offering relevant deals, content, and recommendations.</li>
              <li><strong>Communication:</strong> We may use your contact information to send you updates, promotions, and important travel information.</li>
            </ul>
            <h5>Your Choices:</h5>
            <p>You have the right to:</p>
            <ul>
              <li>Review and update your personal information in your account setting</li>
              <li>Opt out for marketing communications</li>
              <li>Disable cookies through your browser settings</li>
            </ul>
            <h5>Updates to Privacy Policy:</h5>
            <p>We may update this Privacy Policy to reflect changes in practices or legal requirements. We encourage you to review this policy periodically.</p>
  
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
  };
  
  
export default Privacy;
