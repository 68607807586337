import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import Application from './config/application';

const CancelRefund = () => {
  return (
  <div id="main-wrapper">
    <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
    <Header />
    <section className="page-header page-header-dark bg-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>Cancellation & Refund Policy</h1>
          </div>
          <div className="col-md-4">
            <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
              <li>
                <RouterLink to={PageUrl.HOME}>Home</RouterLink>
              </li>
              <li className="active">Cancellation & Refund Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div id="content">
      <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          <p>At {Application.ORG_DISPLAY_NAME}, we understand that plans can change unexpectedly. Our Cancellation and Refund Policy is designed to provide clarity and guidance on cancellations, changes, and refund procedures. Please review these guidelines carefully before making any bookings.</p>
          <h5>Cancellation Requests:</h5>
          <ul>
            <li>Each airline has its own cancellation policy, which may vary based on the fare type and booking class.</li>
            <li>When making a flight reservation through {Application.ORG_DISPLAY_NAME}, the specific cancellation terms will be provided during the booking process.&nbsp;</li>
          </ul>
          <h5>Refund Eligibility:</h5>
          <ul>
            <li>Refunds are subject to the terms and conditions set by the respective airlines. Some fares may be fully refundable, while others may have partial or no refund options.</li>
            <li>Refunds are usually processed back to the original form of payment used for the booking.</li>
          </ul>
          <h5>Processing Time:</h5>
          <p>Refund processing times vary by airline and payment method. It may take several weeks for the refunded amount to reflect in your account.</p>
          <h5>Changes To Travel Dates:</h5>
          <p>Some airlines may permit changes to travel dates for a fee, subject to availability and fare rules.</p>
          <h5>Processing Fees:</h5>
          <p>Certain cancellations may incur processing fees imposed by the airline or {Application.ORG_DISPLAY_NAME}. These fees will be deducted from the refund amount.</p>
          <h5>No-Show Policy:</h5>
          <p>If you miss your flight without prior notice, the airline's no-show policy may apply, resulting in forfeiture of the fare.</p>
  
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
  };
  
export default CancelRefund;
