import {PAGE_IDS} from '../Constants';

const Application = {
  ORG: 'flightticketsdeal',
  ORG_DISPLAY_NAME: 'FlightTicketsDeal',
  ORG_ADD_LINE1: '',// '1806 Hamilton, Big Spring,',   
  ORG_ADD_LINE2: '',//'TX 79720, USA',
  STORAGE_HOST: 'https://storage.googleapis.com',
  PRODUCTION_API_BASE_URL: 'https://myfaresinfo.com/alpha',
  LOCAL_API_BASE_URL: 'http://localhost:8080/alpha',
  STORAGE_URL_STATIC_ASSETS_PATH: 'https://storage.googleapis.com/sfc-static',
  DOMAIN: 'flightticketsdeal.com',
  SUPPORT_PHONE: '+1 (888) 846-0460',
  CUSTOMER_SUPPORT_PHONE: '+1 (888) 846-0460',
  CONTACT_EMAIL: 'support@flightticketsdeal.com',
  MAP_ADDRESS: '', //'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.7922002194014!2d-101.48761812436064!3d32.209829573905786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86f9228952ca2693%3A0xec14d2d5bce9d302!2s1806%20Hamilton%20St%2C%20Big%20Spring%2C%20TX%2079720!5e0!3m2!1sen!2sus!4v1693106314375!5m2!1sen!2sus',
  CALL_NOW_POPUP_HEADING_TEXT: 'Call now for Booking, Changes and Cancellation',
  CALL_NOW_POPUP_SAVE_PRICE: 50,
  CALL_NOW_POPUP_SAME_PAGE_TIMEOUT_SEC: 3,
  DISCLAIMER: `FlightTicketsDeal is a top travel search site that allows travelers to make flight bookings in a jiffy. We have been in the niche for over a decade now, and this enabled us comprehend customer psyche looking into what we developed this fantastic flight booking system`,
  GA_ID: '',
  PAGE_ID_TO_PHONE: {
    [PAGE_IDS.SpiritAirlines]: '+1 (888) 846-0460'
  }
};
export default Application;
