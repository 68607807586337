import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Destinations = () => {
  return (
  <div id="main-wrapper">
    <Helmet title={composePageTitle('Destinations')} />
    <Header />
    <section className="page-header page-header-dark bg-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>Destinations</h1>
          </div>
          <div className="col-md-4">
            <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
              <li>
                <RouterLink to={PageUrl.HOME}>Home</RouterLink>
              </li>
              <li className="active">Destinations </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div id="content">
      <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          <h5>
            Avail The Perfect Destination By Booking Cheap Flight Ticket
          </h5>
          <p>We offer an extensive range of destinations that cater to every traveller's wanderlust and interests. Whether you're seeking pristine beaches, cultural experiences, adventure, or relaxation, our platform allows you to explore and book flights to a variety of exciting destinations.</p>
          <h5>International Adventures:</h5>
          <p>Embark on international adventures to far-flung corners of the globe. Explore iconic cities like Paris, Rome, and Tokyo, or venture off the beaten path to uncover hidden gems and local cultures.</p>
          <h5>Tropical Paradise:</h5>
          <p>Escape to sun-soaked beaches and tropical paradises. Whether it's the Maldives' overwater bungalows or the white sands of the Caribbean, we have the perfect destinations for relaxation and rejuvenation.</p>
          <h5>Cultural Capitals:</h5>
          <p>Immerse yourself in the rich history and vibrant culture of the world's most renowned cities. Marvel at architectural wonders, indulge in local cuisines, and discover the traditions that make each destination unique.</p>
          <h5>Natural Wonders:</h5>
          <p>Witness awe-inspiring natural wonders that will leave you speechless. From the Northern Lights in Iceland to the Great Barrier Reef in Australia, our destinations cater to those seeking the beauty of the natural world.</p>
          <h5>Adventure Hotspots:</h5>
          <p>For the adrenaline seekers, we offer destinations that promise thrilling experiences. Whether it's hiking in the Andes, diving in the Great Blue Hole, or skiing in the Alps, your next adventure awaits.</p>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <h2 className="text-9 fw-500 text-center">Popular Destinations</h2>
          <p className="lead text-center mb-4">
            World's leading Flight Booking website.
          </p>
          <div className="row g-4 banner">
            <div className="col-md-8">
              <div className="item rounded h-100">
                <div className="caption text-center">
                  <h2 className="text-7">San Diego</h2>
                </div>
                <div className="banner-mask"></div>
                <img className="img-fluid h-100" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/13.jpg`} alt="San Diego" /> 
              </div>
            </div>
            <div className="col-md-4">
              <div className="row g-4">
                <div className="col-12">
                  <div className="item rounded">
                    <div className="caption text-center">
                      <h2>Las Vegas</h2>
                    </div>
                    <div className="banner-mask"></div>
                    <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/14.jpg`} alt="las vegas" />
                  </div>
                </div>
                <div className="col-12 mt-lg-3">
                  <div className="item rounded">
                    <div className="caption text-center">
                      <h2>London</h2>
                    </div>
                    <div className="banner-mask"></div>
                    <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/15.jpg`} alt="London" />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row banner g-4 mt-0 mb-2 mt-lg-3">
            <div className="col-md-4">
              <div className="row g-4">
                <div className="col-12">
                  <div className="item rounded">
                    <div className="caption text-center">
                      <h2>Bangkok</h2>
                    </div>
                    <div className="banner-mask"></div>
                    <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/16.jpg`} alt="Bangkok" />{' '}
                  </div>
                </div>
                <div className="col-12 mt-lg-3">
                  <div className="item rounded">
                    <div className="caption text-center">
                      <h2>Washington</h2>
                    </div>
                    <div className="banner-mask"></div>
                    <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/17.jpg`} alt="Washington" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="item rounded h-100">
                <div className="caption text-center">
                  <h2 className="text-7">New Orleans</h2>
                </div>
                <div className="banner-mask"></div>
                <img className="img-fluid h-100" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/flightticketsdeal/18.jpg`} alt="New orleans" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
  );
  };
  
  
export default Destinations;
