import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
  <div id="main-wrapper">
    <Helmet title={composePageTitle('Terms and Conditions')} />
    <Header />
    <section className="page-header page-header-dark bg-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>Terms and Conditions</h1>
          </div>
          <div className="col-md-4">
            <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
              <li>
                <RouterLink to={PageUrl.HOME}>Home</RouterLink>
              </li>
              <li className="active">Terms and Conditions</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div id="content">
      <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          <div>
            <p>Welcome to {Application.ORG_DISPLAY_NAME}. By accessing and using our website, you agree to abide by the following Terms and Conditions. Please read these terms carefully before making any bookings or interacting with our platform.</p>
  
            <h5>Booking and Reservation:</h5>
            <ul>
              <li>All bookings are subject to availability and the terms of the respective airlines. Prices may vary based on travel dates, class of service, and other factors.</li>
              <li>It is your responsibility to provide accurate and complete information during the booking process.</li>
            </ul>
            <h5>Payment:</h5>
            <p>We accept various payment methods for flight bookings. Prices displayed usually include taxes, fees, and charges unless otherwise stated.</p>
            <h5>Changes and Cancellations:</h5>
            <ul>
              <li>Changes to bookings, including date changes or passenger details, may incur fees as determined by the airline's policies.</li>
              <li>Cancellation policies vary by airline and fare type. You are responsible for understanding and adhering to the relevant terms.</li>
            </ul>
            <h5>Travel Insurance:</h5>
            <p>We recommend purchasing travel insurance to cover unforeseen events, cancellations, medical emergencies, and other travel-related risks.</p>
            <h5>Liability:</h5>
            <ul>
              <li>{Application.ORG_DISPLAY_NAME} acts as a third-party platform and is not responsible for disruptions, delays, cancellations, or changes made by airlines or third-party suppliers.</li>
              <li>We are not liable for personal injury, property damage, or other losses incurred during travel.</li>
            </ul>
            <p>By using {Application.ORG_DISPLAY_NAME}, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our website and services.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
  };
  
  
export default Terms;
