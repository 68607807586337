import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
  <div id="main-wrapper">
    <Helmet title={composePageTitle('About Us')} />
    <Header />
    <section className="page-header page-header-dark bg-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1>About Us</h1>
          </div>
          <div className="col-md-4">
            <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
              <li>
                <RouterLink to={PageUrl.HOME}>Home</RouterLink>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div id="content">
      <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          <p>Welcome to {Application.ORG_DISPLAY_NAME}, your dedicated resource for hassle-free travel solutions and unbeatable flight deals. At {Application.ORG_DISPLAY_NAME}, we're passionate about making your travel dreams a reality. As a team of travel enthusiasts, we understand the excitement of exploring new destinations and experiencing different cultures.</p>
          <h2>Our Mission</h2>
          <p>Our mission is simple: to provide you with the best travel experiences at the most affordable prices. We believe that everyone deserves the opportunity to travel and create lifelong memories. Whether you're jetting off on a spontaneous adventure or planning a well-deserved vacation, we're here to ensure you get the most out of every journey.</p>
          <h2>Why Choose {Application.ORG_DISPLAY_NAME} ?</h2>
          <ul>
            <li><strong>Unbeatable Deals:</strong> Our strong partnerships with airlines and travel providers allow us to secure exclusive deals and discounts, ensuring that you always find the best value for your money.</li>
            <li><strong>Dedicated Support: </strong>Our customer support team is available around the clock to assist you with any inquiries or concerns. We're committed to providing you with top-notch service at every step of your journey.</li>
            <li><strong>User-Friendly Platform:</strong> We've designed our platform to be intuitive and user-friendly, making it easy for you to browse, compare, and book flights with just a few clicks.</li>
            <li><strong>Privacy and Security: </strong>Your privacy and personal information are of utmost importance to us. We employ the latest security measures to ensure your data is protected throughout your booking process.</li>
          </ul>
          <div className="row mt-4 mb-2">
            <div className="col-md-4">
              <div className="featured-box style-1">
                <div className="featured-box-icon text-primary">
                  {' '}
                  <i className="far fa-thumbs-up"></i>
                </div>
                <h3>Best Deal Offer</h3>
                <p>
                  Find our lowest deal to destinations worldwide, guaranteed
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-1">
                <div className="featured-box-icon text-primary">
                  {' '}
                  <i className="far fa-paper-plane"></i>{' '}
                </div>
                <h3>Easy Booking</h3>
                <p>
                  Search, select and save - the fastest way to book your trip
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-1">
                <div className="featured-box-icon text-primary">
                  {' '}
                  <i className="far fa-eye"></i>{' '}
                </div>
                <h3>24/7 Customer Care</h3>
                <p>
                  Get award-winning service and special deals by calling{' '}
                  {Application.SUPPORT_PHONE}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
  };
  
  
export default About;
